<template>
  <div class="detail__left-section">
    <AwardPreview
      :courseTitle="achievement?.course.display_name"
      :awardTitle="awardTypeTitle"
      :award="achievement.course"
      :infoArr="moreInformation"
      :showTitle="false"
      :issuedOn="achievement.created"
      :userName="username"
    />
  </div>
</template>
<script>
import { AwardPreview } from "didactica";
import getLogo from "@/core/mixins/getLogo";
export default {
  mixins: [getLogo],
  props: {
    achievement: {
      type: Object,
      required: true
    }
  },
  components: {
    AwardPreview
  },
  computed: {
    awardType() {
      return this.achievement.course.content_type.slug;
    },
    awardTypeTitle() {
      let text;
      if (this.awardType === "course_completion_award") {
        text = this.$t("my_achievements.award_of_completion");
      } else if (this.awardType === "course_participation_confirmation") {
        text = this.$t("my_achievements.confirmation_of_participation");
      }
      return text;
    },
    moreInformation() {
      return [
        { title: "Admission criteria", key: "admission_criteria" },
        { title: "Course description", key: "course_description" },
        { title: "Course activities", key: "course_activity" },
        { title: "Course outcomes", key: "course_outcome" },
        { title: "Assessment", key: "course_assessments" },
        { title: "Other information", key: "other_information" }
      ];
    },
    username() {
      return `${this.achievement.user.first_name} ${this.achievement.user.last_name}`;
    }
  },
  methods: {
    infoTabOpen(data) {
      return "my-collapse" + data.id;
    }
  }
};
</script>

<style lang="scss">
.detail__left-section {
  width: 70%;
  padding-right: 10px;
  .award__preview--wrapper {
    .accordion-content {
      text-align: left;
    }
    .date-information {
      .date {
        text-align: left;
      }
    }
    .disclaimer,
    .more-information {
      text-align: left;
    }
  }
}
[dir="rtl"] {
  .detail__left-section {
    .award__preview--wrapper {
      .accordion-content,
      .disclaimer,
      .more-information {
        text-align: right;
      }
      .date-information {
        .date {
          margin: 0 8px 0 0;
        }
      }
      .issued-by {
        .logo {
          margin: 0 24px 0 0;
        }
      }
    }
  }
}
</style>
